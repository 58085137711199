import React, { memo } from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
// sections for this page
import Grid from '@material-ui/core/Grid';

import { CardContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

import ScrollButton from '../components/ScrollButton';
import { Card } from 'reactstrap';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const AgilityTransformationPage = memo(() => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "network-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, webpOptions: { quality: 100 })
          }
        }
      }
    `
  );
  const image = getImage(backgroundImage123);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead3"
    >
      <Layout fullMenu>
        <Seo
          title="Agility Transformation"
          description="Agility transformations need you; we are here to partner in your success. We help by analysing the current state and helping create the three horizon strategic roadmap. Once complete we are here with you, one iteration at a time."
        />
        <ScrollButton />
        <article id="main">
          <header>
            <h2>INCREASING CUSTOMER VALUE TOGETHER</h2>
            <p>FRAMEWORK AGNOSTIC</p>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <section>
                <header>
                  <h4>
                    The best person to lead an agility transformation is you!
                  </h4>
                  <p>
                    We partner with leaders to measure the current state,
                    provide a roadmap of the issues you may face and then
                    provide guidance along the way.
                  </p>
                </header>

                <p>
                  Our clients come to us for varied reasons, however, at the
                  root of their concerns are four key areas of pain.
                </p>

                <div className="row">
                  <div className="col-6 col-12-medium">
                    <ul>
                      <li>
                        Retaining and motivating employees in a global market
                      </li>
                      <li>Decreased revenue to cost ratio</li>
                      <li>Losing market share</li>
                      <li>Data driven decision making</li>
                    </ul>
                  </div>
                </div>
                <p>
                  Our agility consultants love people and people are at the
                  heart of any agility transformation. Over the years Surge has
                  seen the average brand loyalty increase by 88.2%, sales
                  increase by 58.8%, functional delivery increase by 82.4% and
                  customer satisfaction increase by 88.2%
                </p>

                <p>How can we help?</p>
              </section>

              <section>
                <ul className="features3">
                  <li>
                    <StaticImage
                      src="../assets/images/business_agility_current_state.jpg"
                      alt="current state analysis"
                      placeholder="BLURRED"
                      quality="80"
                    />
                    <h4>
                      Current State <br></br>Analysis
                    </h4>
                    <p>
                      By understanding the current culture, ways of working and
                      value generated, we can give you a starting point for your
                      journey.
                    </p>
                  </li>
                  <li>
                    <StaticImage
                      src="../assets/images/agility_leadership_development.png"
                      alt="Leadership Development"
                      placeholder="BLURRED"
                      quality="80"
                      aspectRatio={4 / 3}
                    />
                    <h4>
                      Leadership <br></br>Development
                    </h4>
                    <p>
                      Your leaders will shape the organisation, we partner with
                      leaders to create goals, maximise flow and empower people
                      for success.
                    </p>
                  </li>
                  <li>
                    <StaticImage
                      src="../assets/images/project.jpg"
                      alt="Strategic Roadmaps"
                      placeholder="BLURRED"
                      quality="80"
                      aspectRatio={4 / 3}
                    />
                    <h4>
                      Strategic <br></br>Roadmap
                    </h4>
                    <p>
                      With a future vision in place and a current state in hand,
                      we can help you develop a strategic roadmap that maximises
                      value.
                    </p>
                  </li>
                </ul>
              </section>

              <section id="two" className="wrapper alt style3">
                <section className="spotlight">
                  <div className="image">
                    <StaticImage
                      src="../assets/images/business_agility_radar.png"
                      alt="Business Agility Radar"
                      placeholder="BLURRED"
                      quality="80"
                    />
                  </div>
                  <div className="content">
                    <h4>Yours, 100% Free:</h4>
                    <h2>Agility Readiness Scorecard</h2>

                    <p>
                      Adapting an organisation to respond to customer needs in a
                      flexible way drives better business outcomes. Agility
                      allows organisations to prospere by focusing on Customer
                      Value. Take our FREE Scorecard and in under 2 minutes
                      you'll receive customised strategy report with
                      personalised action steps!{' '}
                    </p>
                    <a
                      href="https://agility.scoreapp.com/"
                      className="button default"
                    >
                      Current State
                    </a>
                  </div>
                </section>
              </section>
              <section className="wrapper alt style1">
                <div
                  style={{
                    borderTop: '2px solid #000 ',
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 50,
                    marginTop: 50,
                  }}
                ></div>
                <h3>Our Three Horizon Strategy</h3>
                <p>
                  {' '}
                  After working with hundreds of teams, we used our data to
                  recommend a reduced risk approach to transforming your
                  organisation for greater customer value. We believe that over
                  complicating transformations leads to failure, once you know
                  your current state and your future state vision, you can
                  incrementally move your organisation towards agility and stop
                  when your competitive advantage begins to decrease relative to
                  the cost of the transformation.
                </p>
                <h5>We call this the SIT model.</h5>
              </section>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        1 | Stabilise
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Everything begins at an objective starting point. The
                        first stage is always to stabilise the metrics with 90%
                        visability and predictability, established leadership
                        practices, end to end value mapped and smaller delivery
                        cycles. From the Business Agility Radar, this is to
                        solidify your current state.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        2 | Iterate
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Leveraging Kaizen change management, you can use
                        outcomes and hypothesis to trial work flow structure
                        changes, new roles, new leadership practices, greater
                        empowerment and customer involvement. From a culture
                        perspective, this is to move towards an outcome focused
                        organisation.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        3 | Transcend
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Once your organisation is ready, the last stage is to
                        move to coaching leadership practices, flatter
                        hierarchies, customers are on the board and rapid
                        iterations for innnovation. This is the goal of an
                        agility transformation with the benefits that follow.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <ul className="actions">
                  <li>
                    <a
                      href="/contact"
                      className="button primary icon solid fa-phone"
                    >
                      Need more info?
                    </a>
                  </li>
                </ul>
              </Grid>
              <section className="wrapper alt style1">
                <div
                  style={{
                    borderTop: '2px solid #000 ',
                    marginLeft: 20,
                    marginRight: 20,
                    marginBottom: 50,
                    marginTop: 50,
                  }}
                ></div>
                <h3>Understand where you are going!</h3>
                <p>
                  {' '}
                  Once you know your current state, its time to focus on your
                  future state. You may have already looked at the
                  recommendations with Stabilise, Iterate and Transcend, here is
                  our process to really understand the road ahead. The seven
                  circumstances of strategy are around our central change
                  management tool; The Kaizen Roadmap is a simple yet effective
                  visualisation tool for determining Objectives, creating
                  Outcomes to test and delivering Outputs, while measuring
                  objectively against customer value.
                </p>

                <div className="box alt">
                  <div className="row gtr-50 gtr-uniform">
                    <div className="col-12">
                      <span className="image fit">
                        <StaticImage
                          src="../assets/images/7strategy.png"
                          alt="7 circumstances of strategy for agility transformation"
                          placeholder="BLURRED"
                          quality="80"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <ul className="features4">
                  <li>
                    <h4>
                      WHY |<br></br>Purpose Canvas
                    </h4>
                    <p>
                      Starting with a purpose canvas; what is our vision, our
                      values and the mission of the organisation.
                    </p>
                  </li>
                  <li>
                    <h4>
                      WHO | <br></br>Customer Persona
                    </h4>
                    <p>
                      Your oganisation needs to have empathy for all and then
                      define which niche customer segmentations you want to
                      serve.
                    </p>
                  </li>
                  <li>
                    <h4>
                      WHERE | <br></br>Customer Journey
                    </h4>
                    <p>
                      Mapping out your customer(s) journey builds a detailed
                      view of the industry, geography and behaviours of your
                      customer(s).
                    </p>
                  </li>
                  <li>
                    <h4>
                      WHAT | <br></br>Value Proposition
                    </h4>
                    <p>
                      Defining the problem, you are solving, including critical
                      success factors and blue ocean competitor analysis.
                    </p>
                  </li>
                </ul>
                <ul className="features3">
                  <li>
                    <h4>
                      HOW | <br></br>Service Portfolio
                    </h4>
                    <p>
                      Creating a service portfolio to match the customers will
                      show the resources and capabilities needed for your
                      organisation.
                    </p>
                  </li>
                  <li>
                    <h4>
                      In What Way | <br></br>Org Design
                    </h4>
                    <p>
                      Target operating model, ways of working, leadership,
                      customer value metrics and org design.
                    </p>
                  </li>
                  <li>
                    <h4>
                      WHEN | <br></br>Adaptive Delivery
                    </h4>
                    <p>
                      Setting up dynamic roadmaps, cadence calendars and KPIs
                      will ensure continuous improvements baked in.
                    </p>
                  </li>
                </ul>

                <ul className="actions">
                  <li>
                    <a
                      href="/contact"
                      className="button primary icon solid fa-phone"
                    >
                      Need more info?
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </section>
          <section className="wrapper style2">
            <section id="two" className="wrapper alt style2">
              <section className="spotlight">
                <div className="image">
                  <StaticImage
                    src="../assets/images/mikey.jpg"
                    alt="Michael Law"
                    placeholder="BLURRED"
                    quality="80"
                  />
                </div>
                <div className="content">
                  <h2>
                    Agility Transformation |
                    <br />
                    Practice
                  </h2>
                  <p>
                    "The transition from efficient product offerings,
                    product-centric to finally customer-centric can be
                    challenging. Cross functionality is not about diferent
                    technology people working together, it is the whole value
                    system working together under a single customer
                    segmentation. Lastly, its not about Agile delivery, it is
                    about business agility! <br></br>The way your organisation
                    adapts to respond to the customer needs in a flexible way.
                    During my thesis on customer value, I found over 80% of
                    transformations were failures, this was due to not realising
                    how to measure, taking an old view on human transformation
                    and focusing on big bang solutions.<br></br> A partnership
                    with Surge is just that, a partnership; we have walked these
                    miles before and we can support you on your journey, one
                    iteration at a time."{' '}
                  </p>

                  <ul className="actions">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/michael-law-74010a39/"
                        className="button primary"
                      >
                        Contact Mikey
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://businessagility.institute/learn/adaptive-design-increasing-customer-value-in-your-organisation/492"
                        className="button icon solid fa-download"
                      >
                        Thesis
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </section>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default AgilityTransformationPage;
